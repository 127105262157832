@import 'src/styles/component-utils';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  color: $greyscale-0;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
  white-space: nowrap;

  transition-property: color, background-color, border-color;
  transition-timing-function: ease;
  transition-duration: 200ms;


  .dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:focus-visible {
    outline-color: $secondary-400;
    outline-style: auto;
    outline-width: 2px;
  }


  &.small {
    padding: 0 16px;
    height: 32px;
  }

  &.medium {
    padding: 0 24px;
    height: 40px;
  }

  &.xs {
    @apply font-brand-t4m;
    padding: 0;
    height: 24px;

    background-color: transparent !important;
  }

  &.primary {
    background-color: $primary-300;

    &:hover,
    &:active,
    &.active {
      background-color: $primary-400;
    }
  }

  &.secondary {
    background-color: $secondary-300;

    &:hover,
    &:active,
    &.active {
      background-color: $secondary-400;
    }
  }

  &.danger {
    background-color: $danger-400;

    &:hover,
    &:active,
    &.active {
      background-color: $danger-500;
    }
  }

  &.grey {
    background-color: $greyscale-100;
    border: 1px solid $greyscale-200;
    color: $greyscale-500;

    .dots {
      fill: $greyscale-500;
    }

    &:hover {
      background-color: $greyscale-200;
      border: 1px solid $secondary-400;
      color: $secondary-400;
    }

    &:active,
    &.active {
      background-color: $greyscale-100;
      border: 1px solid $secondary-300;
      color: $secondary-300;
    }
  }

  &.text-grey {
    background-color: transparent;
    color: $greyscale-500;

    &:hover,
    &:active,
    &.active {
      color: $secondary-300;
      background: $greyscale-100;
    }

    .dots {
      fill: $greyscale-500;
    }

    &:disabled {
      color: $greyscale-300;
    }
  }

  &.text-secondary {
    background-color: transparent;
    color: $secondary-300;

    &:hover,
    &:active,
    &.active {
      background: $greyscale-100;
      color: $secondary-400;
    }

    .dots {
      fill: $secondary-300;
    }
  }

  &.text-dark {
    background-color: transparent;
    color: $greyscale-300;

    &:hover,
    &:active,
    &.active {
      color: $secondary-300;
    }

    .dots {
      fill: $greyscale-300;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
}

.link {
  display: inline-flex;
  position: relative;
  min-width: 0;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease;
  transition-duration: 200ms;

  &.secondary {
    color: $secondary-300;
  }

  &.grey {
    color: $greyscale-600;
  }

  &:hover,
  &:active,
  &.active {
    color: $secondary-400;
  }

  &:focus-visible {
    outline: 2px solid $secondary-400;
  }


  &.xs {
    @apply font-brand-c1r;
  }

  &.s {
    @apply font-brand-b2r;
  }

  &.m {
    @apply font-brand-b1;
  }

  &.l {
    @apply font-brand-t2r;
  }

  &.xl {
    @apply font-brand-t1r;
  }

  &.xs-bold {
    @apply font-brand-c1m;
  }

  &.s-bold {
    @apply font-brand-b2m;
  }

  &.m-bold {
    @apply font-brand-t4m;
  }

  &.l-bold {
    @apply font-brand-t2m;
  }

  &.xl-bold {
    @apply font-brand-t1m;
  }
}

.icon {
  display: inline-flex;
  flex: none;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;

  transition-property: color, background-color, border-color;
  transition-timing-function: ease;
  transition-duration: 200ms;

  &.s {
    height: 24px;
    width: 24px;
  }

  &.m {
    height: 32px;
    width: 32px;
  }

  &.grey {
    color: $greyscale-500;
    background: transparent;

    &:hover, &.active {
      background-color: $greyscale-100;
    }
  }

  &.secondary {
    color: $secondary-300;
    background: transparent;

    &:hover {
      color: $secondary-400;
    }
  }

  &.grey-outline {
    color: $greyscale-500;
    background: $greyscale-100;
    border: 1px solid $greyscale-200;

    &:hover {
      color: $secondary-400;
      border-color: $secondary-400;
    }
    
    &.active {
      border: 1px solid $secondary-300;
      color: $secondary-300;
    }
  }

  &.grey-dark {
    color: $greyscale-400;
    background: transparent;

    &:hover {
      color: $greyscale-300;
      background-color: $greyscale-550;
    }

    &.active {
      background-color: $greyscale-550;
      border: 1px solid $secondary-300;
      color: $secondary-300;
    }
  }

  &:hover {
    background: $greyscale-100;
  }

  &:focus-visible {
    outline: 2px solid $secondary-400;
  }

  &:disabled,
  &.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
}
