@import 'src/styles/component-utils';

.indicator {
  @include center;
  height: 56px;
}

.circle {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 9999px;
  background-color: $greyscale-300;
}

.circle + .circle {
  margin-left: 28px;
}

.circle.active {
  background-color: $primary-300;
}

.circle + .circle:after {
  position: absolute;
  left: -26px;
  top: 3px;
  content: '';
  height: 2px;
  width: 24px;
  border-radius: 4px;
  background-color: $primary-100;
}

.circle + .circle:not(.active):after {
  background-color: $greyscale-100;
}
