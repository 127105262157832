@import 'src/styles/component-utils';

.container {
  align-items: center;
  background-color: $secondary-100;
  border-radius: 4px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  justify-content: space-between;
  padding: 8px;

  &.disabled {
    opacity: 0.5;
  }

  &.invalid {
    background-color: $danger-50;
  }
}

.label {
  color: $greyscale-500;
  margin-bottom: 0;
}

.error {
  text-align: start;
}
