@import 'src/styles/component-utils';

.tableCell {
  border: solid $greyscale-200;
  border-width: 0 1px 1px 0;
  vertical-align: top;
  padding: 0;
  height: var(--cell-height);
  min-height: var(--cell-height);
  max-height: var(--cell-height);
  width: var(--cell-width);
  min-width: var(--cell-width);
  max-width: var(--cell-width);
  pointer-events: none;
}

.sticky {
  position: sticky;
  background: $greyscale-0;
}

.stickyLeft {
  composes: sticky;
  left: 0;
  z-index: 3;
}

.stickyTop {
  composes: sticky;
  top: 0;
  z-index: 2;
}
