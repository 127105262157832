@import 'src/styles/component-utils';

// TODO dedupe with shifts grid
// target table inside the container and make this part of the container?

.table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  border: $greyscale-200 solid;
  border-width: 1px 0 1px 0;
}

.table tbody tr:last-child td {
  border-bottom-width: 0;
}

.table th:last-child,
.table td:last-child {
  border-right-width: 0;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.dot.blue {
  background-color: $secondary-300;
}

.dot.green {
  background-color: $success-300;
}

.dot.yellow {
  background-color: $warning-300;
}

.dot.violet {
  background-color: $info-200;
}
