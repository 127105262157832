@import 'src/styles/component-utils';

.cell {
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: $greyscale-600;
  box-shadow: 0 1px 0 $greyscale-200;
  min-height: 48px;
  height: 100%;
  transition: background-color .2s ease;
}

.th {
  @apply truncate text-sm text-greyscale-500;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
  background-color: $greyscale-50;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 16px;
  border-bottom: 1px solid $greyscale-200;
  cursor: default;
}

.th:first-of-type {
  padding-left: 24px;
}

.th:last-of-type {
  padding-right: 24px;
}

.thTextContainer {
  display: flex;
  align-items: center;
  height: 24px;
  position: relative;
  max-width: max-content;
}

.thTextContainer.sortable {
  cursor: pointer;
  @include transition();

  .icon {
    @include transition();
    opacity: 0;
    margin-left: 2px;
  }
}

.thTextContainer:hover .icon {
  opacity: 1;
  color: $greyscale-500;
}

.thTextContainer.sortable::before {
  @include transition();
  position: absolute;
  top:0;
  bottom:0;
  left: -5px;
  right: -5px;
  z-index: -1;
  content: ' ';
  border-radius: 2px;
}

.thTextContainer.sortable:hover::before {
  background-color: $greyscale-200;
}

.thTextContainer.sorted .icon {
  color: $secondary-400;
  opacity: 1;
}

.table {
  width: 100%;
  display: grid;
  border-collapse: collapse;
  table-layout: fixed;
}
