@import 'src/styles/component-utils';

$option-height: 32px;

.container {
  font-size: 14px;
  background-color: $greyscale-0;
  border-radius: 6px;
  border: 1px solid $greyscale-300;
  box-shadow: 2px 0 8px #0002;
  max-height: $option-height * 4 + 2;
  overflow-y: auto;
  z-index: 35;
}

.options {
  margin: 0;
}

.option {
  display: flex;
  margin: 0;
  width: 100%;
  align-items: center;
  padding: 0 8px;
  line-height: $option-height;
  height: $option-height;
  color: $greyscale-500;
  cursor: pointer;
}

.option:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.optionActive {
  color: $secondary-400;
  background-color: $greyscale-100;
}

.notFound {
  composes: option;
  border-radius: 6px;
}

.chevron {
  cursor: pointer;
  transform: rotate(-180deg);
  width: 16px;
  @include transition();
}

.chevronActive {
  transform: rotate(0);
}

.dark {
  .option {
    background-color: $greyscale-50;
  }

  .optionActive {
    color: $secondary-400;
    background-color: $greyscale-200;
  }
}

.darken {
  &.container {
    @apply font-brand-t2m;
    max-height: $option-height * 5;
    background-color: $greyscale-550;
    border: 1px solid $greyscale-525;
    min-width: 240px;
  }

  .option {
    color: $greyscale-200;
    background-color: transparent;
  }

  .optionActive {
    color: $secondary-300;
    background-color: transparent;
  }
}
