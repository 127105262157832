@import 'src/styles/component-utils';

// TODO dedupe with ShiftsRms/TableCells

.tableCell {
  position: relative;
  padding: 0;
  vertical-align: top;
  border: solid $greyscale-200;
  border-width: 0 1px 1px 0;
  height: var(--cell-height);
  min-height: var(--cell-height);
  max-height: var(--cell-height);
  width: var(--cell-width);
  min-width: var(--cell-width);
  max-width: var(--cell-width);
}

.hoverable:hover:after,
.active::before {
  position: absolute;
  content: '';
  height: 20000px;
  width: 100%;
  left: 0;
  top: -10000px;
  pointer-events: none;
  z-index: 2;
}

.active::before {
  border: 1px solid $primary-300;
  border-top: 0;
  border-bottom: 0;
}

.hoverable:hover:after {
  background-color: rgba($primary-300, 0.1);
  opacity: 0.5;
}
