@import 'src/styles/component-utils';

$border-radius: 8px;

.progressContainer {
  position: relative;
  height: 4px;
  width: 100%;
  border-radius: $border-radius;
}

.progress {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: $border-radius;
  will-change: width;
}

// Background variants

.progressContainer.bg-grey {
  background-color: $greyscale-400;
}
.progressContainer.bg-transparent {
  background-color: transparent;
}

// Color variants

.progress.primary {
  background-color: $primary-400;
}
.progress.secondary {
  background-color: $secondary-400;
}
.progress.danger {
  background-color: $danger-400;
}
.progress.grey {
  background-color: $greyscale-400;
}
.progress.white {
  background-color: $greyscale-0;
}
