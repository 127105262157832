@import 'src/styles/component-utils';

.item {
  @include center;
  position: relative;
  height: 40px;
  width: 40px;
}

.hoverable {
  border: 1px solid transparent;
  border-radius: 9999px;
  transition: all .15s ease;
}

.hoverable:hover {
  border-color: $secondary-300;
}

.rangeDay::after {
  counter-reset: content var(--content);
  content: counter(content);
  position: absolute;
  top: 0;
  left: 0;
  @include center;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 9999px;
  transition: all .3s ease;
}

.rangeDay::after:hover {
  border-color: $secondary-300;
}

.rangeDaySelected::after {
  color: $greyscale-0;
  background: $secondary-300;
}

.rangeDaySelected {
  background: $warning-100;
}

.fill {
  background: $greyscale-100;
}
