@import 'src/styles/component-utils';

.container {
  background-color: theme('colors.greyscale.50');
  max-height: calc(100vh - 100px);
  min-height: 200px;
}

.container > div:nth-child(2n) {
  background-color: theme('colors.greyscale.100');
}

.center {
  @include center;

  flex-direction: column;
  gap: 1;
}
