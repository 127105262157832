@import 'src/styles/component-utils';

.capacityItem.green {
  background-color: $success-50;
  color: $success-300;
}

.capacityItem.blue {
  background-color: $secondary-100;
  color: $secondary-400;
}

.capacityItem.yellow {
  background-color: $warning-100;
  color: $warning-400;
}

.capacityItem.violet {
  background-color: $info-100;
  color: $info-400;
}

.capacityItem.red {
  background-color: $danger-100;
  color: $danger-400;
}