@import 'src/styles/component-utils';

.field {
  border-radius: 4px;
  border: 1px solid $greyscale-300;
  font-size: 14px;
  font-weight: 400;
  min-height: 100px;
  min-width: 200px;
  padding: 8px;
  resize: vertical;

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: $greyscale-300;
  }

  &:focus {
    border-color: $secondary-400;
    outline: none;
  }

  &.invalid {
    background-color: $danger-50;
    border-color: $danger-100;
  }
}
