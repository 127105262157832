@import 'src/styles/component-utils';

@mixin margining($s, $mt, $mb: $mt) {
  @if ($mt) {
    * + #{$s} {
      margin-top: $mt;
    }
  }

  #{$s} + * {
    margin-top: $mb;
  }
}

.editor {
  position: relative;
}

.editor :global {
  .lexical-textBold {
    font-weight: bold;
  }

  .lexical-textItalic {
    font-style: italic;
  }

  .lexical-textUnderline {
    text-decoration: underline;
  }

  .lexical-textStrikethrough {
    text-decoration: line-through;
  }

  .lexical-textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }

  .lexical-h1 {
    @apply font-brand-h1;
  }

  .lexical-h2 {
    @apply font-brand-t1m;
  }

  .lexical-h3,
  .lexical-h4,
  .lexical-h5,
  .lexical-h6 {
    @apply font-brand-t2m;
  }

  ul,
  ol {
    margin-left: 24px;
  }

  ul > li {
    list-style: disc;
  }

  ol > li {
    list-style-type: decimal;
  }

  .lexical-quote {
    position: relative;
    padding-left: 12px;
  }

  .lexical-quote:before {
    content: '';
    height: 100%;
    position: absolute;
    left: 0;
    width: 3px;
    background-color: $greyscale-300;
    border-radius: 9999px;
  }

  .lexical-link {
    display: inline-flex;
    position: relative;
    min-width: 0;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    transition-property: color, background-color, border-color;
    transition-timing-function: ease;
    transition-duration: 200ms;
    color: $secondary-300;
    cursor: pointer;

    &:hover,
    &:active {
      color: $secondary-400;
    }

    &:focus-visible {
      outline: 2px solid $secondary-400;
    }
  }

  hr {
    color: $greyscale-300;
  }

  @include margining(hr, 12px);
  @include margining('.lexical-paragraph', 8px);
  @include margining('.lexical-heading', 12px, 4px);
  @include margining('.lexical-ul', 4px, 8px);
  @include margining('.lexical-ol1', 4px, 8px);
}

[data-lexical-placeholder]::after {
  content: attr(data-placeholder-text);
  position: absolute;
  left: 0;
  top: 0;
  color: $greyscale-500;
  pointer-events: none;
}
