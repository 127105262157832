@import 'src/styles/component-utils';

.wrapper {
  @apply font-brand-b1;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
  margin: 0;
  color: $greyscale-400;
  user-select: none;
  cursor: pointer;
}

.checkmark {
  color: $greyscale-400;
  font-size: 21px;
  width: 24px;
  height: 24px;
}

.checkmark + .text {
  margin-left: 8px;
}

.checked {
  .checkmark {
    color: $success-300;
  }
}

.disabled {
  .checkmark {
    color: $greyscale-500;
  }

  .text {
    color: $greyscale-500;
  }
}

.checked.disabled {
  .checkmark {
    color: $greyscale-500;
  }

  .text {
    color: $greyscale-400;
  }
}
