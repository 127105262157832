@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

button:focus,
input:focus {
  outline: 2px solid transparent;
}

body {
  font-family: 'Poppins', sans-serif;
  color: $greyscale-600;
  background-color: $greyscale-75;
}

html,
body,
#root {
  height: 100%;
}

.hide .top-loading-bar,
.hide .layout-top-header,
.hide .navigation {
  display: none;
}

// Remove arrows in input type="number"
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
