.width-100,
.w-100 {
  width: 100%;
}

.width-50,
.w-50 {
  width: 50%;
}

.width-fit-content {
  width: fit-content;
}

.height-100,
.h-100 {
  height: 100%;
}

.min-height-100vh,
.mh-100vh {
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

.bold,
.fw-700 {
  font-weight: 700;
}

.semibold,
.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.normal,
.fw-400 {
  font-weight: 400;
}

.uppercase,
.upper {
  text-transform: uppercase;
}

.lowercase,
.lower {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.min-width-0,
.mw-0 {
  /* Flex column container fix */
  min-width: 0;
}

.font-size-0,
.fs-0 {
  font-size: 0;
}

.font-weight,
.fw {
  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }
}

.max-width-100,
.mx-100 {
  max-width: 100%;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.outline-none {
  outline: none !important;
}

.outline-0 {
  outline: 0 !important;
}

.square {
  &-16 {
    width: 16px;
    height: 16px;
  }
}
