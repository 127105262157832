@import 'src/styles/component-utils';

.heading {
  align-items: center;
  background-color: $greyscale-100;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-left: 8px;
  height: 32px;
}

.title {
  color: $greyscale-500;
  font-size: 14px;
  font-weight: 500;
}

.edit {
  color: $secondary-300;
  font-size: 14px;
  font-weight: 500;
}
