@import 'src/styles/component-utils';

.container {
  background-color: $greyscale-0;
  border-radius: 4px;
  border: 1px solid $greyscale-300;
  display: flex;
  flex-direction: column;
  margin-top: -4px;
  max-height: 160px;
  max-width: max-content;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}

.toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
}

.item {
  color: $greyscale-500;
  font-size: 13px;
  height: 32px;
  padding: 0 8px;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  gap: 8px;

  &.selected {
    color: $secondary-400;
  }

  &:active,
  &:hover {
    background-color: $greyscale-200;
    color: $secondary-400;
    cursor: pointer;
  }

  &.disabled {
    color: $greyscale-400;
  }

  & > span, & > span > * {
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
