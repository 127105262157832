@import 'src/styles/component-utils';

.switcher {
  @include transition();

  flex: none;
  border-radius: 12px;
  height: 24px;
  position: relative;
  width: 42px;

  &:disabled {
    opacity: 0.5;
  }
}
