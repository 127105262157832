@import 'src/styles/component-utils';

.banner {
  border-radius: 4px;
  padding: 4px 8px;

  @apply font-brand-b2r;
}

.banner > .title {
  @apply font-brand-t5;
}

.banner.success {
  background-color: $primary-50;
  border: 1px solid $primary-300;
  color: $primary-400;
}

.banner.warning {
  background-color: $warning-50;
  border: 1px solid $warning-300;
  color: $warning-500;
}

.banner.error {
  background-color: $danger-50;
  border: 1px solid $danger-400;
  color: $danger-400;
}

.banner.info {
  background-color: $info-50;
  border: 1px solid $info-100;
  color: $info-400;
}
