@import 'src/styles/component-utils';

.backdrop {
  background: #0007;
  z-index: 20;
  transition-property: opacity;
}

.modal {
  margin: 32px auto;
  width: 712px;
  background: $greyscale-0;
  border-radius: 4px;
  box-shadow: 2px 0 6px #0005;
  transition-property: opacity, transform;
}

.backdrop[data-status='initial'] {
  opacity: 0;
}

.backdrop[data-status='open'] {
  opacity: 1;
  transition: all .3s ease-out;
}

.backdrop[data-status='close'] {
  opacity: 0;
  transition: all .1s ease-in;
}

.modal[data-status='initial'] {
  opacity: 0;
  transform: translateY(-60px);
}

.modal[data-status='open'] {
  opacity: 1;
  transform: translateY(0);
  transition: all .3s ease-out;
}

.modal[data-status='close'] {
  opacity: 0;
  transform: translateY(-20px);
  transition: all .1s ease-in;
}
