@import 'src/styles/component-utils';

.container {
  border-radius: 4px;
  border: 1px solid $greyscale-200;
  display: flex;
  padding: 12px 16px;
}

.container.default {
  border: 1px solid $success-400;
}
