@import 'src/styles/component-utils';

.point {
  position: absolute;
}

.marker {
  position: absolute;
  // half of the marker width
  left: -18px;
  bottom: 0;
  font-size: 48px;

  .stroke {
    @include transition();
    color: $danger-500;
  }

  .fill {
    @include transition();
    color: $danger-400;
    filter: drop-shadow(0 0 8px $greyscale-700);
  }

  .index {
    @include transition();
    color: $greyscale-0;
  }
}

.marker:hover {
  .fill {
    color: $danger-500;
  }
}

.marker.resolved {
  .stroke {
    color: $greyscale-600;
  }

  .fill {
    color: $greyscale-0;
  }

  .index {
    color: $greyscale-600;
  }
}

.marker.resolved:hover {
  .fill {
    color: $greyscale-100;
  }
}

.marker.selected {
  .stroke {
    color: $primary-400;
  }

  .fill {
    color: $greyscale-0;
    filter: drop-shadow(0 0 8px $primary-400);
  }

  .index {
    color: $primary-400;
  }
}

.marker.selected:hover {
  .fill {
    color: $greyscale-100;
  }
}

.index {
  font-size: 19px;
  font-weight: 500;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
