@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($property: all, $duration: 300ms, $function: ease) {
  transition: $property $duration $function;
}

/**
 * Usage: @include font-size(12, 18);
 */
@mixin font-size($font-size, $line-height: normal) {
  font-size: $font-size * 1px;

  @if $line-height == normal {
    line-height: normal;
  } @else {
    line-height: $line-height * 1px;
  }
}

/**
 * Usage:
 *
 * @include box(24) => { width: 24px; height: 24px };
 * @include box(24, 48) => { width: 24px; height: 48px };
 */
@mixin box($width, $height: $width) {
  min-width: $width * 1px;
  min-height: $height * 1px;
  width: $width * 1px;
  height: $height * 1px;
}
