.wrapper {
  align-items: center;
  border-radius: 4px;
  border: 1px solid theme('colors.greyscale.300');
  display: flex;
  height: 40px;
  padding: 0 8px;
  position: relative;
  width: 100%;

  &:focus-within {
    border-color: theme('colors.secondary.300');
  }
}

.input {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 24px);
}

.chevron {
  align-items: center;
  color: theme('colors.greyscale.500');
  display: flex;
  height: 32px;
  justify-content: center;
  margin-left: auto;
  width: 32px;
}
