@import 'src/styles/component-utils';

.checkbox {
  &.primary {
    color: $primary-300;

    &:hover,
    &:active,
    &.active {
      color: $primary-400;
    }
  }

  &.secondary {
    color: $secondary-300;

    &:hover,
    &:active,
    &.active {
      color: $secondary-400;
    }
  }

  input:focus-visible+& {
    outline-color: $secondary-400;
    outline-style: auto;
    outline-width: 20px;
  }
}