@import 'src/styles/component-utils';

// TODO dedupe with rms breakdown
// target table inside the container and make this part of the container?

.table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  border: $greyscale-200 solid;
  border-width: 1px 0 1px 0;
}

.table tbody tr:last-child td {
  border-bottom-width: 0;
}

.table th:last-child,
.table td:last-child {
  border-right-width: 0;
}

.todayCell:before {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  border: solid $primary-300;
  border-width: 0 1px 0 1px;
  z-index: 2;
}

.selection {
  position: absolute;
  height: 32px;
  margin-left: 4px;
  border-radius: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%236D7395FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
  pointer-events: none;
  z-index: 1;
}
