@import 'src/styles/component-utils';

.tooltip {
  pointer-events: none;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: 2px 0 8px #0002;
  z-index: 9999;
  @apply font-brand-c1r;
}

.tooltip.white {
  background: $greyscale-0;
  color: $greyscale-500;
  border: 1px solid $greyscale-300;
}

.tooltip.lightGrey {
  background: $greyscale-100;
  color: $greyscale-500;
}

.tooltip.grey {
  background: $greyscale-600;
  color: $greyscale-0;
}

.tooltip.danger {
  background: $danger-500;
  color: $greyscale-0;
}

.arrow path:first-child {
  stroke-width: 2px !important;
  stroke: transparent;
}

.white.arrow {
  fill: $greyscale-0;
  & path:first-child {
    stroke: $greyscale-300 !important;
  }
}

.lightGrey.arrow {
  fill: $greyscale-100
}

.grey.arrow {
  fill: $greyscale-600;
}

.danger.arrow {
  fill: $danger-500;
}
