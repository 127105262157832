@import 'src/styles/component-utils';

.item {
  font-size: 14px;
  color: $greyscale-400;
  width: 100%;
  @include transition();
}

.opened.item {
  background-color: $greyscale-550;
}

.active {
  color: $greyscale-50;
  background-color: $greyscale-550;
}

.innerList {
  @include transition();
}

.link {
  display: flex;
  align-items: center;
  color: inherit;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  @include transition();
}

.link:hover {
  color: $greyscale-50;
  background-color: $greyscale-525;
  text-decoration: none;
}

.item .icon {
  min-width: 40px;
  min-height: 40px;
  font-size: 20px;
}

.innerList .item .icon {
  min-height: 32px;
  font-size: 6px;
}

.circleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 32px;
}

.circle {
  height: 8px;
  width: 8px;
  border: 2px solid currentColor;
  border-radius: 50%;
  @include transition();
}

.circle.filled {
  background-color: currentColor;
}

.chevron {
  min-width: 40px;
  min-height: 40px;
  margin-left: auto;
  transform: rotate(-90deg);
  font-weight: 900;
  @include transition();
}

.opened .chevron {
  transform: rotate(90deg);
}
