.container {
  max-height: 100%;
  min-height: min(595px, 100%);
  background-color: theme('colors.greyscale.0');
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 8px;
  justify-content: center;
  padding: 8px;
  width: 100%;
}

.container.extended {
  gap: 0;
  padding: 0;
  width: 792px;
  max-height: calc(100vh - 120px);
}

.wrapper {
  flex: 1;
  background-color: theme('colors.greyscale.50');
  border-radius: 4px;
  border: 1px solid theme('colors.greyscale.200');
  height: 100%;
  padding: 8px;
}

.wrapper.extended {
  margin: 20px 24px 0;
  padding: 16px;
}

.field {
  border: 0;
  font-size: 14px;
  align-self: center;
  height: 24px;
  line-height: 24px;
  max-height: 48vh; 
  outline: none;
  padding: 0 8px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: theme('colors.greyscale.400');
    font-size: 14px;
  }
}

.uploadFile {
  align-items: center;
  background-color: theme('colors.greyscale.100');
  border-radius: 4px;
  border: 2px dashed theme('colors.greyscale.200');
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 40px 0;
}

.uploadFile.dragging {
  border: 2px solid theme('colors.secondary.300');
}
