@import 'src/styles/component-utils';

.status {
  @apply font-brand-b2r;

  &.success {
    color: $success-300;
  }

  &.danger {
    color: $danger-400;
  }

  &.warning {
    color: $warning-400;
  }

  &.info {
    color: $info-400;
  }
}