.flex,
.f {
  display: flex;

  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &-0,
  &-none {
    flex: none;
  }

  &-1,
  &-auto {
    flex: auto;
  }

  &-terminal {
    flex: 1 0 auto;
  }

  &-center-center,
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-around-center {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &-justify-between {
    display: flex;
    justify-content: space-between;
  }

  &-justify-center {
    display: flex;
    justify-content: center;
  }

  &-justify-start {
    display: flex;
    justify-content: flex-start;
  }

  &-justify-end {
    display: flex;
    justify-content: flex-end;
  }

  &-align-start {
    display: flex;
    align-items: flex-start;
  }

  &-align-center {
    display: flex;
    align-items: center;
  }

  &-align-end {
    display: flex;
    align-items: flex-end;
  }

  &-align-stretch {
    display: flex;
    align-items: stretch;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-shrink-0 {
    flex-shrink: 0;
  }

  &-shrink-1 {
    flex-shrink: 1;
  }
}
