@import 'src/styles/component-utils';

.controlPanel {
  position: absolute;
  left: 4px;
  bottom: 4px;
  z-index: 2;
  display: flex;
  gap: 8px;
}

.controlPanel.zoomPanelOpen {
  .contentWrapper {
    display: flex;
  }

  .resetButton {
    margin-left: -16px;
  }
}

.contentWrapper {
  display: none;
  gap: 8px;
  position: relative;
  overflow: hidden;
  cursor: default;
  transform: translateX(-16px);
}

.zoomPanelContent {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  padding-left: 16px;
}

.buttonsBlock {
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 4px;

  &:not(:last-child) {
    border-right: 2px solid $greyscale-400;
  }
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.imageWrapper {
  position: relative;
}

.image {
  opacity: 1;
  min-width: 100%;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

.image.notInited {
  opacity: 0;
}
