* {
  scrollbar-width: thin;
  scrollbar-color: $greyscale-300 transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin-block: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: $greyscale-300;
  border-radius: 9999px;
  border: 1px solid $greyscale-0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $greyscale-400;
}

html {
  scrollbar-color: $greyscale-500 transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: $greyscale-500;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: $greyscale-525;
}
