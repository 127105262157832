@import 'src/styles/component-utils';

.iterationsBar {
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 8px;
  width: 100%;
}

.iterationsBar .chunk {
  height: 8px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.iterationItem {
  align-items: center;
  border-bottom: 1px solid $greyscale-200;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  width: 100%;
}

.iterationItem .title {
  color: $greyscale-500;
  font-size: 14px;
  font-weight: 400;
}

.iterationItem .points {
  color: $greyscale-600;
  font-size: 14px;
  font-weight: 500;
}

.iterationItem .color {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
