@import 'src/styles/component-utils';

.capacityPlanning {
  font-size: 14px;
  width: 100%;
}

.capacityPlanning .row {
  align-items: center;
  display: flex;
}

.capacityPlanning .cell {
  align-items: center;
  border-bottom: 1px solid $greyscale-200;
  border-right: 1px solid $greyscale-200;
  color: $greyscale-500;
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: center;
  min-width: 125px;

  &:first-child {
    background: $greyscale-0;
    justify-content: flex-start;
    left: 0;
    max-width: 180px;
    min-width: 180px;
    position: sticky;
    z-index: 1;
  }

  &:last-child {
    border-right: none;
  }
}
