@import 'src/styles/component-utils';

.container {
  position: relative;
}

.container:not(:last-child) {
  padding-bottom: 24px;

  &::after {
    background-color: $greyscale-200;
    content: '';
    display: block;
    height: 100%;
    left: 11px;
    position: absolute;
    top: 24px;
    width: 2px;
  }
}

.arrow {
  align-items: center;
  background-color: $greyscale-0;
  border-radius: 50%;
  border: 2px solid $greyscale-400;
  display: flex;
  @include box(24);
  justify-content: center;
  z-index: 2;
}
