@import 'src/styles/component-utils';

@keyframes theme-loading {
  50% {
    transform: scale(1.2);
  }
}

.themeLoader {
  @include box(72);

  align-items: center;
  background-color: $greyscale-0;
  border-radius: 50%;
  display: flex;
  justify-content: center;

  &.animated {
    animation: theme-loading 3s ease-in-out infinite;
  }
}

.themeLoaderModal {
  align-items: center;
  background-color: $greyscale-50;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.image {
  height: 36px;
  width: auto;
}
