@import 'src/styles/component-utils';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  min-width: 300px;
  background-color: $secondary-100;
  border-radius: 4px;
  font-size: 14px;
}

.title {
  color: $greyscale-500;
  @include truncate;
}

.inputWrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $greyscale-300;
  background: $greyscale-0;
  height: 32px;
  padding: 0 8px;
  width: 100px;
}

.input {
  font-size: inherit;
  color: inherit;
  width: 100%;
  background: transparent;
  border: 0;
  @include truncate;

  &::placeholder {
    color: $greyscale-500;
  }
}

.regular .inputWrapper {
  width: 160px;
}

.input:focus {
  border-color: $secondary-400;
}

.children {
  display: flex;
  align-items: center;
  color: $greyscale-500;
  max-height: 32px;
  cursor: pointer;
}

.children > * {
  @include center;
  @include box(32);
}

// Fix select showing wrong selected item when opening from unfocused state using chevron
.children > [class*='chevron'] {
  pointer-events: none;
}

.wrapper:focus-within .children > [class*='chevron'] {
  pointer-events: auto;
}

.wrapper:focus-within {
  .inputWrapper {
    border-color: $secondary-400;
  }
}

.error {
  background-color: $danger-50;

  .inputWrapper {
    color: $danger-400;
    background-color: $danger-50;
    border: 1px solid $danger-100;
  }

  .inputWrapper:focus-within {
    border-color: $danger-400;
  }
}

.readOnly .inputWrapper {
  background-color: $secondary-100;
  border-color: transparent;
  pointer-events: none;
}

.disabled {
  background-color: $secondary-50;

  .inputWrapper {
    background-color: $secondary-50;
    border-color: $secondary-50;
  }
}

.hasChildren {
  .inputWrapper {
    padding-right: 0;
  }
}

.center .input {
  text-align: center;
}

.right .input {
  text-align: right;
}
