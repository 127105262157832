@import 'src/styles/component-utils';

.timelineItem {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
  margin-left: 4px;
  padding-left: 16px;
  overflow: hidden;
  z-index: 1;
  border-radius: 16px;
  @apply transition-colors;
}

.timelineItem.blue {
  background-color: rgba(235, 242, 255, 0.6);
  border: 1px solid $secondary-200;
  color: $secondary-300;
}

.timelineItem.blue:hover {
  background-color: $secondary-200;
}

.timelineItem.green {
  background-color: rgba(218, 252, 252, 0.6);
  border: 1px solid $success-100;
  color: $success-300;
}

.timelineItem.green:hover {
  background-color: $success-100;
}

.timelineItem.yellow {
  background-color: rgba(255, 245, 222, 0.6);
  border: 1px solid $warning-300;
  color: $warning-400;
}

.timelineItem.yellow:hover {
  background-color: $warning-100;
}

.timelineItem.violet {
  background-color: rgba(240, 235, 250, 0.6);
  border: 1px solid $info-200;
  color: $info-400;
}

.timelineItem.violet:hover {
  background-color: $info-100;
}

.timelineItem.red {
  background-color: rgba(255, 237, 238, 0.6);
  border: 1px solid $danger-300;
  color: $danger-400;
}

.timelineItem.red:hover {
  background-color: $danger-100;
}

.sign {
  display: flex;
  align-items: center;
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  margin-top: -1px;
  @include box(16, 32);
}

.timelineItem.isManager {
  .range {
    padding-right: 40px;
  }
}

.icon {
  @include box(24);
  font-size: 12px;
  color: $greyscale-500;
}

.managerIcon {
  composes: icon;
  position: absolute;
  right: 12px;
}

.chevron {
  composes: icon;
  margin-left: auto;
  color: $greyscale-500;
  font-size: 12px;
  margin-right: 8px;
}

.timelineItem:not(:hover) .chevron {
  display: none !important;
}

.range {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 16px;
}

.timelineItem.resizeable:hover {
  .range,
  .managerIcon {
    display: none !important;
  }
}

.time {
  @apply font-brand-c1r;
  color: $greyscale-500;
}

.resize {
  position: absolute;
  @include box(8, 32);
  cursor: ew-resize;
}

.resizeLeft {
  composes: resize;
  left: 0;
}

.resizeRight {
  composes: resize;
  right: 0;
}

.updating:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    105deg,
    transparent 0,
    transparent 10px,
    $greyscale-0 40px,
    transparent 50px,
    transparent 60px
  );
  animation: 20s linear 0s infinite normal update-breathe;
}

@keyframes update-breathe {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 5000px 0;
  }
}
