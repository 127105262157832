@import 'src/styles/component-utils';

.wrapper {
  display: block;
  font-size: 14px;
  width: 100%;
  pointer-events: none;
  margin: 0;
}

.inputWrapper {
  display: flex;
  pointer-events: auto;
  position: relative;
}

.icon {
  @include box(40);
  @include center;
  color: $greyscale-400;
  background-color: $greyscale-100;
  border: 1px solid $greyscale-300;
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.input {
  border-radius: 4px;
  border: 1px solid $greyscale-300;
  font-size: inherit;
  padding: 0 8px;
  height: 40px;
  width: 100%;
  background-color: $greyscale-0;

  &::placeholder {
    color: $greyscale-500;
  }

  &:autofill {
    background-color: $greyscale-0;
  }
}

.children {
  @include center;
  color: $greyscale-500;
  background-color: $greyscale-0;
  border: 1px solid $greyscale-300;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  max-height: 40px;
}

.children > * {
  @include center;
  @include box(40);
}

// Fix select showing wrong selected item when opening from unfocused state using chevron
.children > [class*="chevron"] {
  pointer-events: none;
}

.wrapper:focus-within .children > [class*="chevron"] {
  pointer-events: auto;
}

.small {
  font-size: 13px;

  .icon {
    @include box(32);
  }

  .input {
    height: 32px;
  }

  .children {
    max-height: 32px;
  }

  .children > * {
    @include box(32);
  }
}

.disabled {
  .icon {
    color: $greyscale-300;
    border-color: $greyscale-200;
    background-color: $greyscale-50;
  }

  .inputWrapper, .input {
    cursor: not-allowed;
  }

  .input {
    color: $greyscale-400;
    border-color: $greyscale-200;
  }

  .input::placeholder {
    color: $greyscale-400;
  }

  .children {
    border-color: $greyscale-200;
  }

  .children > * {
    pointer-events: none;
  }
}

.hasIcon .input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.hasChildren .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  padding-right: 0;
}

.noIconBorder {
  .input {
    border-left: 0;
    padding-left: 0;
  }

  .icon {
    background-color: transparent;
  }
}

.wrapper:focus-within {
  &.noIconBorder .icon,
  .children,
  .input {
    border-color: $secondary-400;
  }
}

.error:focus-within {
  &.noIconBorder .icon,
  .children,
  .input {
    border-color: $danger-400;
  }
}

.error {
  .icon, .input, .input:autofill {
    background-color: $danger-50;
    border-color: $danger-100;
    color: $danger-400;
  }

  .children {
    background-color: $danger-50;
    border-color: $danger-100;
  }
}

.readOnly {
  .input {
    cursor: pointer;
  }

  .input::selection {
    background-color: transparent;
    color: inherit;
  }
}

.themeFilter {
  .icon, .input, .children {
    background-color: $secondary-300;
    border-color: $secondary-400;
    color: $greyscale-0;
  }

  .input::placeholder {
    color: $greyscale-50;
  }
}

.themeDark {
  .icon, .input, .children {
    background-color: $greyscale-100;
    color: $greyscale-500;
  }

  .input::placeholder {
    color: $greyscale-500;
  }
}

.squareLeft  .inputWrapper > *:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.squareRight  .inputWrapper > *:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.center .input {
  text-align: center;
}

.right .input {
  text-align: right;
}
