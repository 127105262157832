@import 'src/styles/component-utils';

.nav {
  grid-column: 1/2;
  grid-row: 1/3;
  width: 40px;
}

.inner {
  display: grid;
  grid-template-rows: auto 1fr;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: $greyscale-600;
  width: 200px;
  @include transition();
}

.inner.collapsed {
  width: 40px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  padding: 16px;
  border-bottom: 1px solid $greyscale-525;
}

.collapsed .link {
  padding: 8px;
}
