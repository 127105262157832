@import 'src/styles/component-utils';

.badge {
  @apply truncate;

  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 32px;
  border: 1px solid;

  &.small {
    @apply font-brand-c2m;
    height: 18px;
    padding: 1px 6px;
  }

  &.medium {
    @apply font-brand-t5;
    height: 24px;
    padding: 2px 8px;
  }

  &.accent {
    color: $accent-400;
    background-color: $accent-50;
    border-color: $accent-100;
  }

  &.accent-red {
    color: $accent-red-400;
    background-color: $accent-red-50;
    border-color: $accent-red-100;
  }

  &.accent-salad-green {
    color: $accent-salad-green-800;
    background-color: $accent-salad-green-50;
    border-color: $accent-salad-green-100;
  }

  &.danger {
    color: $danger-400;
    background-color: $danger-50;
    border-color: $danger-100;
  }

  &.danger-solid {
    color: $greyscale-0;
    background-color: $danger-400;
    border-color: transparent;
  }

  &.grey-solid {
    color: $greyscale-100;
    background-color: $greyscale-500;
    border-color: transparent;
  }

  &.grey {
    color: $greyscale-500;
    background-color: $greyscale-100;
    border-color: $greyscale-300;
  }

  &.lighter-grey {
    color: $greyscale-500;
    background-color: $greyscale-50;
    border-color: $greyscale-300;
  }

  &.info {
    color: $info-400;
    background-color: $info-50;
    border-color: $info-100;
  }

  &.primary {
    color: $primary-400;
    background-color: $primary-50;
    border-color: $primary-100;
  }

  &.secondary {
    color: $secondary-400;
    background-color: $secondary-50;
    border-color: $secondary-200;
  }

  &.secondary-solid {
    color: $greyscale-100;
    background-color: $secondary-300;
    border-color: transparent;
  }

  &.success {
    color: $success-400;
    background-color: $success-50;
    border-color: $success-100;
  }

  &.warning {
    color: $warning-500;
    background-color: $warning-50;
    border-color: $warning-100
  }
}
