@import 'src/styles/component-utils';

.badge {
  align-items: center;
  background: $greyscale-50;
  border-radius: 32px;
  border: 1px solid $greyscale-300;
  color: $greyscale-500;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 12px;
}

.badge.completed {
  background-color: $primary-100;
  border-color: $success-400;
  color: $primary-400;
}

.badge.canceleted {
  background-color: $danger-50;
  border-color: $danger-400;
  color: $danger-500;
}

.badge.current {
  background-color: $greyscale-500;
  border-color: $greyscale-500;
  color: $greyscale-0;
}

$option-height: 40px;

.option {
  color: $greyscale-500;
  height: $option-height;
  padding: 8px;
  text-align: start;

  span {
    display: block;
  }

  @include transition();

  &:active,
  &:hover {
    background-color: $greyscale-100;
    color: $secondary-400;
    cursor: pointer;
  }
}
