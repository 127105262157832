@import 'src/styles/component-utils';

$wrapper-margin-width: 300px;
$annotation-list-width: $wrapper-margin-width - 16px;

.uploadPreviews {
  align-items: center;
  background-color: theme('colors.greyscale.650');
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  gap: 8px;
  flex-grow: 1;
  justify-content: center;
  margin: 16px;
  margin-top: 0;
}

.uploadPreviews.dragging {
  border: 2px solid theme('colors.secondary.300');
}

.wrapper {
  margin: 0 16px 16px;
  flex-grow: 1;
  position: relative;
  overflow-x: hidden;
}

.previewsWrapper {
  display: flex;
  gap: 16px;
  height: 100%;
  transition: margin 0.3s ease;
}

.previewsWrapper.annotationListOpened {
  margin-right: $wrapper-margin-width;
}

.previews {
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: theme('colors.greyscale.700');
  border-radius: 4px;
  width: 220px;
  min-width: 220px;
  padding-bottom: 16px;
}

.editHeader {
  @apply font-brand-t4m;

  display: flex;
  align-items: center;
  flex: none;
  height: 36px;
  padding: 4px 5px 0 0;
  margin-bottom: 8px;
}

.addPreview {
  width: 172px;
  aspect-ratio: 16 / 9;
  align-items: center;
  background-color: theme('colors.greyscale.600');
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 16px;

  &:active,
  &:hover {
    cursor: pointer;
  }
}

.preview {
  display: flex;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: auto;
  border-radius: 4px;
}

.previewItem {
  position: relative;
  border-radius: 4px;
  margin-right: 16px;
  width: 100%;
  aspect-ratio: 16 / 9;

  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    background-color: $greyscale-0;
  }
}

.previewItem::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  border: 2px solid transparent;
  pointer-events: none;
  @include transition();
}

.previewItem.active::after {
  border: 2px solid $secondary-300;
}

.previewItem .annotationsIcon {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 12px;
  height: 24px;
  width: 24px;
  background-color: theme('colors.danger.400');
  color: theme('colors.greyscale.0');
  border-radius: 50%;
}

.annotationList {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: $annotation-list-width;
  background: $greyscale-700;
  border-radius: 4px;
  overflow-y: auto;
  transform-origin: right;
  display: flex;
  flex-flow: column;
  padding-bottom: 16px;
}

.enter {
  transform: translateX($annotation-list-width);
}

.enterActive {
  transform: translateX(0);
  @include transition();
}

.exit {
  transform: translateX(0);
}

.exitActive {
  transform: translateX($annotation-list-width);
  @include transition();
}
