@import 'src/styles/component-utils';

.wrapper {
  grid-column: 1/3;
  grid-row: 1/3;
  background-color: theme('colors.greyscale.600');
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;

  * {
    scrollbar-color: $greyscale-800 transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $greyscale-800;
    border: 1px solid transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $greyscale-800;
  }
}
