@import 'src/styles/component-utils';

.success {
  background: $success-50;
  border-color: $success-400;
}

.info {
  background: $info-50;
  border-color: $info-400;
}

.warning {
  background: $warning-50;
  border-color: $warning-300;
}

.error {
  background: $danger-50;
  border-color: $danger-400;
}

.default {
  background: $secondary-50;
  border-color: $secondary-300;
}
