@import 'src/styles/component-utils';

.image {
  opacity: 1;
  min-width: 100%;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

.image.notInited {
  opacity: 0;
}

.container {
  width: 360px;
  height: 360px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: grab;
  align-self: center;
}

.circle {
  position: absolute;
  z-index: 1;
  border-radius: 100%;
  outline: rgba($greyscale-500, 0.5) 100vmax solid;
}
