@import 'src/styles/component-utils';

.row {
  align-items: center;
  display: flex;
}

.cell {
  border-bottom: 1px solid $greyscale-200;
  padding: 8px;
  width: 96px;
}

.cell.pricing {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.treatment {
  display: flex;
  justify-content: center;
  background-color: $greyscale-100;
  border-radius: 4px;
  color: $greyscale-500;
  padding: 8px;
  width: 96px;
}

.priceLabels {
  align-items: center;
  color: $greyscale-500;
  display: flex;
  font-size: 13px;
  justify-content: space-around;
}
