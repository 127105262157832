@import 'src/styles/component-utils';

.paginationItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  padding: 0 11px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: $greyscale-500;
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
  @include transition();

  &:hover {
    text-decoration: none;
    color: $greyscale-500;
  }

  &:hover:not(.disabled) {
    background-color: $greyscale-100;
    cursor: pointer;
  }

  &.active {
    color: $secondary-400;
    border-color: $secondary-400;
    background-color: $greyscale-100;
  }

  &.icon {
    font-size: 11px;
  }

  &.disabled {
    color: $greyscale-300;
  }
}
