.boundary-box, .bb {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;

  &-16 {
    @extend .boundary-box;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  &-24 {
    @extend .boundary-box;
    width: 24px;
    height: 24px;
  }

  &-32 {
    @extend .boundary-box;
    width: 32px;
    height: 32px;
  }

  &-80 {
    @extend .boundary-box;
    width: 80px;
    height: 80px;
  }
}
