@import 'src/styles/component-utils';
// Adapted from https://css-tricks.com/multi-thumb-sliders-particular-two-thumb-case

.outerWrap {
  height: 64px;
}

.title {
  @apply font-brand-b1;
}

.innerWrap {
  --width: 100%;
  --height: 8px;
  --diameter: 24px;
  --radius: calc(0.5 * var(--diameter));
  --uw: calc(var(--width) - var(--diameter));
  --diff: calc(var(--max) - var(--min));
  top: 24px;
  position: relative;
  display: grid;
  grid-template: var(--height) / 1fr 1fr;
  width: var(--width);
  background: $greyscale-100;
  border-radius: 5px;

  &::before,
  &::after {
    grid-column: 1 / span 2;
    grid-row: 1;
    background: $primary-300;
    content: '';
  }

  &::before {
    margin-left: calc(var(--radius) + (var(--a) - var(--min)) / var(--diff) * var(--uw));
    width: calc((var(--b) - var(--a)) / var(--diff) * var(--uw));
  }

  &::after {
    margin-left: calc(var(--radius) + (var(--b) - var(--min)) / var(--diff) * var(--uw));
    width: calc((var(--a) - var(--b)) / var(--diff) * var(--uw));
  }
}

@mixin thumb {
  box-sizing: border-box;
  background: $greyscale-0;
  border: 3px solid $primary-300;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  pointer-events: auto;
  cursor: pointer;
}

.range {
  grid-column: 1 / span 2;
  grid-row: 1;
  z-index: 1;
  margin: 0;
  background: none;
  pointer-events: none;

  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb,
  & {
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track,
  &::-moz-range-track {
    background: none;
  }

  &::-webkit-slider-thumb {
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }

  &:focus {
    z-index: 2;
  }

  &:focus-visible {
    &::-webkit-slider-thumb {
      @apply ring;
    }
    &::-moz-range-thumb {
      @apply ring;
    }
  }
}

.start,
.end {
  position: absolute;
  display: flex;
  justify-content: center;
  color: $greyscale-500;
  top: 20px;
  transform: translateX(-50%);
  width: 100px;
  @apply font-brand-b2r;
}

.start {
  left: calc(var(--radius) + (var(--a) - var(--min)) / var(--diff) * var(--uw));
}

.end {
  left: calc(var(--radius) + (var(--b) - var(--min)) / var(--diff) * var(--uw));
}
