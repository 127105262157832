$primary-50: #e8f5f1;
$primary-100: #cbeadd;
$primary-300: #46b688;
$primary-400: #2c9475;

$secondary-50: #f3f7ff;
$secondary-100: #e5eefd;
$secondary-200: #bed7ea;
$secondary-300: #7394e8;
$secondary-400: #537adb;

$greyscale-0: #ffffff;
$greyscale-50: #f8fafc;
$greyscale-75: #f5f5f9;
$greyscale-100: #f0f4f9;
$greyscale-200: #e3e9f2;
$greyscale-300: #cdd7e5;
$greyscale-400: #9196af;
$greyscale-500: #6d7395;
$greyscale-525: #444b5e;
$greyscale-550: #393f4f;
$greyscale-600: #2a3142;
$greyscale-650: #202737;
$greyscale-700: #181e2f;
$greyscale-800: #00071a;

$success-50: #e9fdfd;
$success-100: #abeae2;
$success-300: #1ec1a4;
$success-400: #24a78f;

$warning-50: #fff9eb;
$warning-100: #ffedc2;
$warning-300: #ffd37c;
$warning-400: #ffba0a;
$warning-500: #ee9c16;

$danger-50: #fff4f5;
$danger-100: #ffe1e1;
$danger-300: #fbb1b1;
$danger-400: #f86666;
$danger-500: #e14949;

$info-25: #f6f3fc;
$info-50: #ede9f9;
$info-100: #d1c4e9;
$info-200: #b9a7e8;
$info-400: #8a6dd9;

$accent-50: #fff7eb;
$accent-100: #ffe8c6;
$accent-400: #ff7b4b;

$accent-red-50: #fdeff3;
$accent-red-100: #fcdee7;
$accent-red-400: #ef5c88;

$accent-salad-green-50: #e3f4e1;
$accent-salad-green-100: #c8e6c9;
$accent-salad-green-800: #3f9237;
