@import 'src/styles/component-utils';

.container {
  grid-column: 2/3;
  grid-row: 1/2;
  align-items: center;
  background-color: $greyscale-0;
  border-bottom: 1px solid $greyscale-300;
  display: flex;
  height: 56px;
  justify-content: right;
  padding: 16px;
}

